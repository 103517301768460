import React from 'react'
import { LabeledCheckbox, Action } from '@zooz/generic-ui-components'

import style from './NewBrandingPopoverContent.scss'

const MESSAGE_TITLE = `PayU HUB is now Enterprise`
const MESSAGE_SUBTITLE = `Same Great Experience, New Name!`
const MESSAGE_CONTENT = 'Enjoy the same great service with a new name! Don\'t worry \n this change won\'t impact your platform experience or how you run your business.'
const DONT_SHOW_AGAIN = 'Got it! Don’t show this message again'

export interface NewLanguageMessageProps {
  dismiss: () => void
}
const NewBrandingPopoverContent: React.FC<NewLanguageMessageProps> = ({ dismiss }) => (
  <div data-test='new-language-message' className={style.NewBrandingPopoverContent}>
    <div
      className={style.NewBrandingPopoverContent__title}
    >
      {MESSAGE_TITLE}
    </div>
    <div
      className={style.NewBrandingPopoverContent__subTitle}
    >
      {MESSAGE_SUBTITLE}
    </div>
    <div>{MESSAGE_CONTENT}</div>
    <div className={style.NewBrandingPopoverContent__footer}>
      <div>
        <LabeledCheckbox className={style.NewBrandingPopoverContent__footer__checkbox} onChange={dismiss}>
          <Action type={Action.TYPES.GHOST}>
            {DONT_SHOW_AGAIN}
          </Action>
        </LabeledCheckbox>
      </div>
    </div>
  </div>
)

export default NewBrandingPopoverContent
