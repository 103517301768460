export default {
  user: 'user',
  userEmail: 'email',
  userId: 'id',
  sessionToken: 'session-token',
  merchantIds: 'merchant-ids',
  activeMerchant: 'active-merchant',
  accountInformation: 'account-information',
  onboarding: 'onboarding',
  activated_account_dismissed: 'activated_account_dismissed',
  walkthroughDismissed: 'walkthrough_dismissed',
  selectedLanguage: 'user-selected-language',
  payUHubBrandingDismissed: 'user-dismissed-payu-hub-branding'
}
