import React, { useEffect } from 'react'
import classNames from 'classnames'
import { H1, Paragraph, Card, Popover, PopoverPlacements } from '@zooz/generic-ui-components'
import { Link } from 'react-router-dom'

import { externalLocations } from '../../../../helpers/tools/locations/externalLocations'
import { LanguageSelection } from '../../../Localize/components'
import Messages from '../../../Messages'
import logo from '../payu-enterprise.svg'
import NewBrandingPopoverContent from './NewBrandingPopoverContent'
import localStorageAccessor from '../../../../helpers/localStorageAccessor/localStorageAccessor'

import css from './PageStructure.scss'

interface PageProps {
  id?: string,
  className?: string,
  title?: string,
  subtitle?: string,
  links?: {
    to?: string,
    id: string,
    text: string
  }[]

}

const Page: React.FC<PageProps> = ({
  id,
  className,
  title,
  subtitle,
  children,
  links
}) => {
  const [isBrandingPopupDismissed, setIsBrandingPopupDismissed] = React.useState(true)

  useEffect(() => {
    let isBrandingPopupDismissedInLocalStorage = false
    try {
      isBrandingPopupDismissedInLocalStorage = localStorageAccessor.dismissedPayUHubBranding
    } finally {
      setIsBrandingPopupDismissed(isBrandingPopupDismissedInLocalStorage)
    }
  }, [])

  return (
    <>
      <Messages />
      <div id={id} className={classNames(css.page, className)}>

        <div className={css.brandingLogoSelector}>
          <Popover
            content={(
              <NewBrandingPopoverContent
                dismiss={() => {
                  setIsBrandingPopupDismissed(true)
                  localStorageAccessor.dismissedPayUHubBranding = true
                }}
              />
            )}
            offset={10}
            isOpened={!isBrandingPopupDismissed}
            placement={PopoverPlacements.BottomRight}
          >
            <a className={css.logo} target='_blank' rel='noopener noreferrer' href={externalLocations.zoozWebsite}>
              <img src={logo} height={95} />
            </a>
          </Popover>
        </div>

        <div className={css.backgroundLogo} />
        <Card type={Card.CARD_TYPES.DEFAULT} className={css.content}>
          <div className={css.header}>
            <H1 type={H1.TYPES.PAGE_TITLE} className={css.title}>
              {title}
            </H1>
            {subtitle ? <Paragraph className={css.subtitle}>{subtitle}</Paragraph> : null}
          </div>
          <div className={css.form}>
            {children}
          </div>
          {
                links && links.length > 0
                  ? (
                    <div className={css.links}>
                      {links.map(link => (
                        <Link key={link.id} id={link.id} to={link.to}>
                          {link.text}
                        </Link>
                      ))}
                    </div>
                    )
                  : null
                }
        </Card>
        <div className={css.languageSelector}>
          <LanguageSelection />
        </div>
      </div>
    </>
  )
}

export default Page
